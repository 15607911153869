<template>
  <div>
    <div :class="isMobile ? 'mobile-box' : 'pc-box'">
      <div class="login">
        <div class="input-group">
          <img
            class="icon"
            src="https://lesson.iapeap.com/images/%E6%89%8B%E6%9C%BA.png"
            alt=""
          />
          <div class="label">工号</div>
          <input
            class="input"
            type="text"
            placeholder="请输入工号"
            v-model="username"
          />
        </div>
        <div class="input-group">
          <img
            class="icon"
            src="https://lesson.iapeap.com/images/%E9%94%81.png"
            alt=""
          />
          <div class="label">旧密码</div>
          <input
            class="input"
            type="password"
            placeholder="请输入旧密码"
            v-model="oldPassword"
          />
        </div>
        <div class="input-group">
          <img
            class="icon"
            src="https://lesson.iapeap.com/images/%E9%94%81.png"
            alt=""
          />
          <div class="label">新密码</div>
          <input
            class="input"
            type="password"
            placeholder="请输入新密码"
            v-model="newPassword"
          />
        </div>
        <button class="btn-login" @click="changePassword">修改密码</button>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from "vant";
import axios from "axios";

export default {
  data() {
    return {
      username: "",
      oldPassword: "",
      newPassword: "",
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth <= 768;
    },
  },
  methods: {
    changePassword() {
      if (!this.username || !this.oldPassword || !this.newPassword) {
        Toast.fail("请完整填写工号、旧密码和新密码");
        return;
      }
      Toast.loading({
        duration: 0,
        forbidClick: true,
        loadingType: "spinner",
        message: "正在修改密码",
      });

      axios
        .post(
          // `https://assess.eapchina.net/changePassword`, // 替换为实际的修改密码接口

          `http://192.168.1.217:8081/assess/changePassword`, // 替换为实际的修改密码接口

          `userName=${encodeURIComponent(
            this.username
          )}&oldPassWord=${encodeURIComponent(
            this.oldPassword
          )}&newPassWord=${encodeURIComponent(this.newPassword)}`
        )
        .then((response) => {
          if (response.data.msg === "success") {
            Toast.clear();
            Toast.success("密码修改成功");
            setTimeout(() => {
              //   返回上一页
              this.$router.go(-1);
            }, 1000);
          } else {
            Toast.fail(response.data.msg);
          }
        })
        .catch((error) => {
          if (error.response) {
            Toast.fail(
              `修改失败：${error.response.status} - ${
                error.response.data.message || "服务器错误"
              }`
            );
          } else if (error.request) {
            Toast.fail("网络错误");
          } else {
            Toast.fail(`修改失败：${error.message}`);
          }
        });
    },
  },
};
</script>

<style scoped>
body {
  margin: 0;
  font-family: Arial, sans-serif;
}
.mobile-box,
.pc-box {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
}
.mobile-box {
  background-image: url("https://lesson.iapeap.com/images/cp/wuzi.png");

  background-size: cover;
  background-position: center;
}
.pc-box {
  background-color: rgb(176, 213, 255);
}
.login {
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
.input-group {
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 25px;
  background-color: #f9f9f9;
  margin-bottom: 20px;
  height: 50px;
  padding: 0 10px;
}
.icon {
  width: 30px;
  height: 30px;
}
.label {
  font-size: 16px;
  color: #5c5857;
  width: 48px;
}
.input {
  border: none;
  outline: none;
  font-size: 16px;
  color: #949494;
  flex: 1;
}
.btn-login {
  background-color: rgb(74, 122, 194);
  color: #fff;
  border: none;
  width: 100%;
  height: 50px;
  border-radius: 20px;
  font-size: 16px;
  cursor: pointer;
}

/* 移动端样式调整 */
@media (max-width: 768px) {
  .login {
    background-color: transparent;
    box-shadow: none;
  }
}
</style>
